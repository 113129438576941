import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword, clearErrors } from "../../actions/userAction";
import { useAlert } from "react-alert";
import MetaData from "../layouts/MataData/MataData";
import { useNavigate, useParams } from "react-router-dom";
import CricketBallLoader from "../layouts/loader/Loader";
import { Avatar, Button, TextField, Typography } from "@mui/material"; 
import LockResetIcon from "@mui/icons-material/LockReset";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link } from "react-router-dom";  // <-- Import Link here

function ResetPassword() {
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alert = useAlert();

  const { error, success, loading } = useSelector(
    (state) => state.forgetPassword
  );

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isPasswordMatch, setIsPasswordMatch] = useState(true); // <-- Track password match status

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setIsValidPassword(newPassword.length >= 8);
    setIsPasswordMatch(newPassword === confirmPassword); // Check match while typing
  };

  const handleConfirmPasswordChange = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
    setIsPasswordMatch(password === newConfirmPassword); // Check match while typing
  };

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (success) {
      alert.success("Password Updated Successfully");
      navigate("/login");
    }
  }, [dispatch, error, alert, success, navigate]);

  function resetPasswordSubmitHandler(e) {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert.error("Password and Confirm Password do not match");
      return;
    }

    const myForm = new FormData();
    myForm.set("password", password);
    myForm.set("confirmPassword", confirmPassword);

    dispatch(resetPassword(token, myForm));
  }

  const isSignInDisabled = !(password && confirmPassword && isValidPassword && isPasswordMatch); // Disable if not matching

  return (
    <>
      <MetaData title="Reset Password" />
      {loading ? (
        <CricketBallLoader />
      ) : (
        <div className="flex justify-center items-center h-screen bg-gray-100 p-4">
          <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-md">
            <div className="flex justify-center mb-4">
              <Avatar className="bg-gradient-to-r from-[#a3812d] to-[#001c25] p-2">
                <LockResetIcon style={{ color: "#fff" }} />
              </Avatar>
            </div>
            <Typography variant="h5" component="h1" className="text-center font-bold text-[#001c25] mb-6">
              Reset Password
            </Typography>

            <form onSubmit={resetPasswordSubmitHandler}>
              <div className="mb-4">
                <TextField
                  label="Password"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  className="text-gray-700"
                  error={!isValidPassword && password !== ""}
                  helperText={
                    !isValidPassword && password !== ""
                      ? "Password must be at least 8 characters."
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <Button
                        variant="outlined"
                        className="text-[#a3812d] border-[#a3812d] hover:bg-[#a3812d] hover:text-white"
                        onClick={handleShowPasswordClick}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </Button>
                    ),
                  }}
                  value={password}
                  onChange={handlePasswordChange}
                />
              </div>

              <div className="mb-6">
                <TextField
                  label="Confirm Password"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  className="text-gray-700"
                  error={!isPasswordMatch && confirmPassword !== ""}
                  helperText={
                    !isPasswordMatch && confirmPassword !== ""
                      ? "Passwords do not match."
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <Button
                        variant="outlined"
                        className="text-[#a3812d] border-[#a3812d] hover:bg-[#a3812d] hover:text-white"
                        onClick={handleShowPasswordClick}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </Button>
                    ),
                  }}
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
              </div>

              <div className="mb-4">
                <Button
                  variant="contained"
                  fullWidth
                  disabled={isSignInDisabled} // Disable button if passwords do not match
                  style={{ backgroundColor: "#a3812d", color: "#fff" }}
                  type="submit"
                >
                  Confirm New Password
                </Button>
              </div>

              <Typography variant="body1" align="center" className="text-[#001c25]">
                <Link to="/login" className="text-[#a3812d] hover:underline">
                  Cancel
                </Link>
              </Typography>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default ResetPassword;
