import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import MetaData from "../layouts/MataData/MataData";
import ScrollToTopOnRefresh from "../Home/ScrollToTop";




const BlogDetails = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const response = await fetch(`/api/blogs/${slug}`);
        const data = await response.json();
        if (data.success) {
          setBlog(data.blog);
        } else {
          console.error("Blog not found");
        }
      } catch (error) {
        console.error("Error fetching blog:", error);
      }
    };

    fetchBlogDetails();
  }, [slug]);

  if (!blog) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <p className="text-lg font-medium text-gray-600">Loading...</p>
      </div>
    );
  }

  return (
    <>
    <ScrollToTopOnRefresh />
      {/* MetaData for SEO */}
      <MetaData
        title={`${blog.title} | Mufa Blog`}
        description={blog.metaDescription || blog.excerpt}
        keywords={blog.metaKeywords || "perfume blog, fragrance tips, Mufa perfumes"}
        ogImage={blog.image || "%PUBLIC_URL%/default-blog-image.jpg"}
        ogUrl={`https://mufaworld.com/blog/${slug}`}
        canonicalUrl={`https://mufaworld.com/blog/${slug}`}
      />

      {/* Main Blog Content */}
      <div className="min-h-screen bg-gray-50">
        <div className="max-w-5xl mx-auto p-5">
          {/* Blog Header */}
          <div className="mb-6 text-center">
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-800 mb-4">
              {blog.title}
            </h1>
            <p className="text-gray-600 text-sm sm:text-base italic">
              Published on {new Date(blog.createdAt).toLocaleDateString()}
            </p>
          </div>

          {/* Blog Image */}
          <div className="mb-6">
            <img
              src={blog.image}
              alt={blog.title}
              className="w-full h-auto rounded-lg shadow-md object-cover"
            />
          </div>

          {/* Blog Content */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div
              className="text-gray-700 leading-relaxed text-base sm:text-lg"
              dangerouslySetInnerHTML={{ __html: blog.content }}
            />
          </div>

          {/* Author Section */}
          <div className="mt-10 flex items-center flex-col sm:flex-row">
            <img
              src={blog.authorImage || "/default-avatar.png"}
              alt={blog.author || "Author"}
              className="w-16 h-16 rounded-full shadow-md mr-0 sm:mr-4 mb-4 sm:mb-0"
            />
            <div className="text-center sm:text-left">
              <p className="text-gray-800 font-bold text-lg">{blog.author || "Anonymous"}</p>
              <p className="text-gray-600 text-sm">{blog.authorBio || "Author information unavailable"}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
