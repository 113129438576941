import React, { useState, useEffect } from "react";
import axios from "axios";
import MetaData from "../component/layouts/MataData/MataData";
import ScrollToTopOnRefresh from "../component/Home/ScrollToTop";
import { Modal, Button } from "react-bootstrap";
import { useNavigate  } from "react-router-dom";

const CareerPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    position: "",
    additionalInfo: "",
  });
  const [resume, setResume] = useState(null);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [captchaAnswer, setCaptchaAnswer] = useState("");
  const [captchaSolution, setCaptchaSolution] = useState(null);
  const [captchaQuestion, setCaptchaQuestion] = useState("");
  
  const navigate  = useNavigate (); // Initialize useHistory hook

  // Generate math CAPTCHA
  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    const question = `${num1} + ${num2} = ?`;
    const solution = num1 + num2;
    setCaptchaQuestion(question);
    setCaptchaSolution(solution);
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setResume(e.target.files[0]);
  };

  const validateForm = () => {
    const newErrors = {};
    const phonePattern = /^[0-9]{10}$/;

    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Valid email is required.";
    if (!formData.phone || !phonePattern.test(formData.phone))
      newErrors.phone = "Phone number should be 10 digits.";
    if (!formData.position) newErrors.position = "Please select a position.";
    if (!resume) newErrors.resume = "Please upload your resume.";
    if (parseInt(captchaAnswer, 10) !== captchaSolution)
      newErrors.captcha = "CAPTCHA answer is incorrect.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const applicationData = new FormData();
    applicationData.append("fullName", formData.name);
    applicationData.append("email", formData.email);
    applicationData.append("phone", formData.phone);
    applicationData.append("position", formData.position);
    applicationData.append("additionalInfo", formData.additionalInfo);
    applicationData.append("resume", resume);

    try {
      const response = await axios.post("/api/v1/careers/apply", applicationData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setMessage(response.data.message || "Application submitted successfully!");
      setShowPopup(true);

      // Reset form and regenerate CAPTCHA
      setFormData({
        name: "",
        email: "",
        phone: "",
        position: "",
        additionalInfo: "",
      });
      setResume(null);
      generateCaptcha();
      setCaptchaAnswer("");

      // Close the modal after a successful submission
      setTimeout(() => {
        setShowPopup(false); // Close the modal after 2 seconds
        navigate("/"); // Redirect to the home page
      }, 2000); // Timeout of 2 seconds before closing the modal and redirecting
    } catch (error) {
      const errorMsg = error.response?.data?.message || "Failed to submit application.";
      setMessage(errorMsg);
      setShowPopup(true);
    }
  };

  const scrollToForm = () => {
    document.getElementById("application-form").scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <ScrollToTopOnRefresh />
      
       {/* Add MetaData for SEO */}
       <MetaData
        title="Career - Job Opportunity in Delhi| Join Our Team | Mufa Perfume"
        description="Explore career opportunities at Mufa Perfumes. We are always looking for passionate individuals to join our team and help us grow. Check out the current job openings!"
        keywords="Mufa Perfumes careers, perfume jobs, fragrance industry jobs, join Mufa Perfumes, perfume job openings"
        ogImage="%PUBLIC_URL%/path-to-careers-image.jpg"
        ogUrl="https://mufaworld.com/career"
        canonicalUrl="https://mufaworld.com/career"
      />

      {/* Success/Error Popup Modal */}
      <Modal
        show={showPopup}
        onHide={() => setShowPopup(false)}
        centered
        style={{
          zIndex: 1050,
          display: "block",
          margin: "auto",
          position: "relative",
          top: "50%",
          transform: "translateY(-50%)",
          width: "400px", // Fixed width
          maxWidth: "90%", // Ensures modal width doesn't exceed 90% of the screen width on smaller devices
        }}
      >
        <Modal.Header
          closeButton
          style={{
            backgroundColor: "#a3812d",
            color: "#fff",
          }}
        >
          <Modal.Title>
            {message.includes("successfully") ? "Success" : "Error"}
          </Modal.Title>
        </Modal.Header>

        {/* Modal Body with background color */}
        <Modal.Body
          className="text-center"
          style={{
            fontSize: "18px",
            padding: "20px",
            color: "#001c25",
            backgroundColor: "#f4f4f4", // Add color to the modal background
            borderRadius: "8px", // Rounded corners for the body
          }}
        >
          {message.includes("successfully") ? (
            <p
              style={{
                fontWeight: "bold",
                color: "#28a745",
                backgroundColor: "#f0f9f0",
                border: "1px solid #28a745",
                padding: "10px",
                borderRadius: "8px",
              }}
            >
              Thank you for submitting your form to Mufa Perfumes! If your resume is shortlisted, we will contact you soon.
            </p>
          ) : (
            <p
              style={{
                fontWeight: "bold",
                color: "#dc3545",
                backgroundColor: "#f9f0f0",
                border: "1px solid #dc3545",
                padding: "10px",
                borderRadius: "8px",
              }}
            >
              {message}
            </p>
          )}
        </Modal.Body>

        <Modal.Footer
          style={{
            backgroundColor: "#001c25",
            color: "#fff",
          }}
        >
          <Button
            onClick={() => setShowPopup(false)}
            style={{
              backgroundColor: "#a3812d",
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="bg-gray-100 min-h-screen p-4 md:p-8 lg:p-12">
        {/* Hero Banner */}
        <section className="relative text-center py-20 bg-gradient-to-r from-[#a3812d] to-[#001c25] text-white rounded-lg mb-10">
          <h1 className="text-5xl font-bold mb-4">Careers in IT & Sales</h1>
          <p className="text-lg">We’re seeking skilled professionals. Check out our open positions below.</p>
        </section>

        {/* IT Software Positions */}
        <section className="mb-10">
          <h2 className="text-3xl font-semibold text-[#a3812d] mb-6 text-center">
            Current Sales Manager Positions
          </h2>
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            {[
              { title: "Software Developer", location: "Remote", status: "closed" },
              { title: "IT Project Manager", location: "On-site", status: "open" },
              { title: "System Analyst", location: "Remote", status: "closed" },
              { title: "Sales Manager", location: "On-site", status: "open" },
              { title: "Graphics Designer", location: "Remote", status: "closed" },
              { title: "Account Manager", location: "On-site", status: "open" },
            ].map((job, index) => (
              <div
                key={index}
                className={`rounded-lg shadow-md p-6 transition-transform hover:scale-105 relative ${
                  job.status === "closed" ? "bg-black text-white" : "bg-[#a3812d] text-white"
                }`}
              >
                <h3 className="text-2xl font-semibold mb-2">{job.title}</h3>
                <p>Location: {job.location}</p>
                {job.status === "closed" && (
                  <span className="absolute top-2 right-2 bg-red-500 text-white text-xs px-2 py-1 rounded-full">
                    Closed
                  </span>
                )}
                <button
                  onClick={scrollToForm}
                  disabled={job.status === "closed"}
                  className={`mt-4 inline-block ${
                    job.status === "closed"
                      ? "opacity-50 cursor-not-allowed"
                      : "text-blue-300 hover:underline"
                  }`}
                >
                  Apply Now
                </button>
              </div>
            ))}
          </div>
        </section>

        {/* Application Form */}
        <section id="application-form" className="bg-[#f0e4d7] p-8 rounded-lg shadow-lg max-w-xl mx-auto">
          <h2 className="text-3xl font-semibold text-[#001c25] text-center mb-6">Apply for a Job</h2>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="flex flex-col">
              <label>Full Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="p-2 border rounded-md"
              />
              {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
            </div>
            <div className="flex flex-col">
              <label>Email Address</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="p-2 border rounded-md"
              />
              {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
            </div>
            <div className="flex flex-col">
              <label>Phone Number</label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="p-2 border rounded-md"
              />
              {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
            </div>
            <div className="flex flex-col">
              <label>Position</label>
              <select
                name="position"
                value={formData.position}
                onChange={handleChange}
                className="p-2 border rounded-md"
              >
                <option value="">Select Position</option>
                <option value="Software Developer">Software Developer</option>
                <option value="Sales Manager">IT Project Manager</option>
                <option value="Sales Manager">System Analyst</option>
                <option value="Sales Manager">Sales Executive</option>
                <option value="Sales Manager">Sales Manager</option>
                <option value="Sales Manager">Account Manager - Sales</option>
              </select>
              {errors.position && <p className="text-red-500 text-sm">{errors.position}</p>}
            </div>
            <div className="flex flex-col">
              <label>Upload Resume</label>
              <input type="file" onChange={handleFileChange} className="p-2 border rounded-md" />
              {errors.resume && <p className="text-red-500 text-sm">{errors.resume}</p>}
            </div>
            {/* CAPTCHA */}
            <div className="flex flex-col">
              <div className="flex items-center space-x-2">
                <label>{captchaQuestion}</label>
                <input
                  type="number"
                  value={captchaAnswer}
                  onChange={(e) => setCaptchaAnswer(e.target.value)}
                  className="p-2 border rounded-md"
                />
              </div>
              {errors.captcha && <p className="text-red-500 text-sm mt-1">{errors.captcha}</p>}
            </div>
            <button
              type="submit"
              className="w-full bg-[#a3812d] text-white p-3 rounded-md hover:bg-[#8c6c24] transition"
            >

              Submit Application
            </button>
          </form>
        </section>
      </div>
    </>
  );
};

export default CareerPage;
