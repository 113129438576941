import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const PopupModal = () => {
  const [showModal, setShowModal] = useState(true); // State to control modal visibility

  useEffect(() => {
    // Prevent scrolling on the body when the modal is open
    if (showModal) {
      document.body.style.overflow = "hidden";
    }

    // Set a timeout to automatically close the modal after 5 seconds
    const timeout = setTimeout(() => {
      setShowModal(false);
      document.body.style.overflow = ""; // Restore scroll behavior
    }, 10000);

    // Clean up and reset the scroll behavior when component unmounts
    return () => {
      clearTimeout(timeout); // Clear timeout on unmount
      document.body.style.overflow = ""; // Reset scroll behavior
    };
  }, [showModal]);

  const closeModal = () => {
    setShowModal(false);
    document.body.style.overflow = ""; // Restore scroll behavior when the modal is closed
  };

  return (
    <>
      <Modal
        show={showModal} // Controlled by state
        onHide={closeModal} // Allow manual close
        centered
        style={{
          zIndex: 1050,
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          width: "800px",
          maxWidth: "90%",
          backgroundColor: "transparent",
          boxShadow: "none",
          border: "none",
          outline: "none",
        }}
        className="responsive-modal" // Add a class for responsive behavior
      >
        {/* Close Button */}
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none"
          onClick={closeModal}
          style={{
            background: "none",
            border: "none",
            fontSize: "20px",
          }}
        >
          ✖
        </button>

        {/* Modal Body with Image Link */}
        <Modal.Body
          className="text-center"
          style={{
            fontSize: "18px",
            padding: "0",
            backgroundColor: "transparent",
            borderRadius: "0",
            border: "none",
            boxShadow: "none",
          }}
        >
          <a
            href="/products" // Replace with your actual product page URL
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://res.cloudinary.com/difmu7prg/image/upload/v1733747598/newyear-sale-model_ukdw0r.jpg"
              alt="New Year Offer"
              className="w-full rounded-md mb-4"
              style={{
                maxWidth: "100%",
                height: "auto",
                border: "none",
              }}
            />
          </a>
        </Modal.Body>
      </Modal>

      {/* Responsive Styles */}
      <style jsx>{`
        .responsive-modal {
          top: 6%; /* Default for desktop */
        }

        @media (max-width: 1024px) and (min-width: 768px) {
          .responsive-modal {
            top: 20%; /* Adjust for tablets */
          }
        }

        @media (max-width: 768px) {
          .responsive-modal {
            top: 30%; /* Adjust for mobile */
          }
        }
      `}</style>
    </>
  );
};

export default PopupModal;
