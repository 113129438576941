import React, { useState, lazy, Suspense } from "react";
import { Typography, Grid, Select, MenuItem, Button } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import CricketBallLoader from "../layouts/loader/Loader";
import { useStyles } from "./ReviewStyle";
import MyCard from "./Card";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useNavigate } from 'react-router-dom';
const DialogBox = lazy(() => import("./DialogBox"));

const ReviewCard = ({ product }) => {
  const classes = useStyles();
  const { isAuthenticated } = useSelector((state) => state.userData);
  const alert = useAlert();
  const navigate = useNavigate();
  const [sortValue, setSortValue] = useState("highest");

  const handleSortChange = (event) => {
    setSortValue(event.target.value);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    if (!isAuthenticated) {
      alert.error("Please Login to write a review");
      navigate("/login");
    }
    setOpen(true);
  };

  const handleClose = () => {
    console.log("called");
    setOpen(false);
  };

  // Fallback for ratings and reviews if undefined
  const ratings = product?.ratings ?? 0;  // Default to 0 if product.ratings is undefined
  const numOfReviews = product?.numOfReviews ?? 0;  // Default to 0 if product.numOfReviews is undefined

  return (
    <div className={classes.reviewRoot}>
      <Typography variant="h5" component="h1" className={classes.reviewHeader}>
        Users Reviews
      </Typography>
      <Button
        variant="contained"
        className={classes.submitBtn}
        fullWidth
        style={{ marginTop: "2rem" }}
        onClick={handleClickOpen}
      >
        Write your Review
      </Button>

      <Suspense fallback={<CricketBallLoader />}>
        <DialogBox
          open={open}
          handleClose={handleClose}
          className={classes.dialog}
        />
      </Suspense>

      <Grid container alignItems="center" style={{ marginTop: "2rem" }}>
        <Grid item className={classes.ratingContainer}>
          <Rating
            value={ratings}
            precision={0.5}
            readOnly
            className={classes.star}
          />
        </Grid>
        <Typography variant="body2" className={classes.ratingNumber}>
          {ratings} stars
        </Typography>
        <Grid item>
          <Typography variant="body2">
            <strong> Total Reviews : </strong>
            {numOfReviews}
          </Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end" className={classes.selectContainer}>
        <Grid item>
          <Typography
            variant="body2"
            style={{ fontSize: "12px" }}
            className={classes.sortBy}
          >
            SortBy :
          </Typography>
        </Grid>
        <Grid item>
          <Select
            value={sortValue ? sortValue : "highest"}
            className={classes.select}
            onClick={handleSortChange}
            MenuProps={{
              anchorOrigin: { vertical: "bottom", horizontal: "left" },
              getContentAnchorEl: null,
              MenuListProps: { disableScrollLock: true },
            }}
          >
            <MenuItem value="highest" className={classes.menuItem}>
              Highest Rated
            </MenuItem>
            <MenuItem value="lowest" className={classes.menuItem}>
              Lowest Rated
            </MenuItem>
            <MenuItem value="latest" className={classes.menuItem}>
              Latest Reviews
            </MenuItem>
            <MenuItem value="oldest" className={classes.menuItem}>
              Oldest Reviews
            </MenuItem>
          </Select>
        </Grid>
      </Grid>

      <div className={classes.container}>
        {product?.reviews && product.reviews.length > 0 ? (
          product.reviews.map((review) => <MyCard review={review} key={review.id} />)
        ) : (
          <Typography variant="body2">No reviews available.</Typography>
        )}
      </div>
    </div>
  );
};

export default ReviewCard;
