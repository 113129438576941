import React from "react";
import { Link } from "react-router-dom";
import MetaData from "../component/layouts/MataData/MataData";
import ScrollToTopOnRefresh from "../component/Home/ScrollToTop";

const About_UsPage = () => {
  return (
    <>
    <ScrollToTopOnRefresh />
    <MetaData 
          title="About Us - Mufa Perfumes | Premium Fragrances for Every Occasion" 
          description="Discover the story behind Mufa Perfumes. Learn about our commitment to quality, luxury fragrances, and the experience we offer to fragrance enthusiasts." 
          keywords="About Mufa Perfumes, premium perfumes, luxury fragrances, brand story, Mufa Perfumes history, best perfumes"
          ogImage="%PUBLIC_URL%/path-to-about-us-image.jpg"
          ogUrl="https://mufaworld.com/about_us"
          canonicalUrl="https://mufaworld.com/about_us"
        />

      <div className="flex flex-col items-center justify-center py-16 bg-white w-full">
        {/* <MetaData title={"About Us"} /> */}
       

        {/* Main Content */}
        <div className="p-8 text-center max-w-full">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 justify-center">
            {/* Image Section */}
            <div className="flex justify-center">
              <img
                src="https://res.cloudinary.com/difmu7prg/image/upload/v1729665744/about_fyto63.webp"
                alt="MufaWorld"
                className="w-full h-auto object-contain max-w-full md:max-w-2xl"
              />
            </div>

            {/* Text Section */}
            <div className="text-left md:pl-8">
              <h1 className="text-xl text-gray-800 font-bold mb-4">About Us</h1>
              <p className="text-justify text-gray-700 leading-relaxed mb-4">
              Founded in 2013, Mufa International Pvt Ltd. is a homegrown brand passionate about luxury, sophistication, and authenticity in every fragrance we create. Since our inception, we have been dedicated to crafting exquisite perfumes, deodorants, and attars that blend the essence of traditional aromatic craftsmanship with contemporary elegance. Our journey began with a simple mission: to offer luxurious, high-quality fragrances that are both accessible and memorable for everyone.
              </p>
              <p className="text-justify text-gray-700 leading-relaxed">
              Every scent we produce at Mufa World tells a story – an olfactory experience meticulously crafted for those who appreciate the art of fine fragrance. We pride ourselves on sourcing the finest ingredients to create long-lasting and captivating scents, each designed to reflect unique personalities and moods. Our product line has expanded over the years, evolving from non-alcoholic attars to a full range of alcohol-based perfumes and body sprays, catering to the diverse preferences of our customers.


              </p>
            </div>
          </div>
        </div>

        {/* Second Section: Who We Are */}
        <div className="p-6 text-center max-w-full">
          <h2 className="text-lg text-gray-800 font-bold mb-4">Who We Are</h2>
          <p className="text-justify text-gray-700 leading-relaxed mb-4">
          At Mufa International Pvt Ltd, our commitment goes beyond just selling perfumes. We believe that fragrances are an extension of one's personality, capturing moments, emotions, and experiences. Our team of skilled perfumers and designers combines expertise with creativity, crafting perfumes that leave a lasting impression. Whether you seek a signature scent for daily wear or something special for unforgettable moments, Mufa is your go-to destination.


          </p>
          <p className="text-justify text-gray-700 leading-relaxed mb-4">
          We also welcome collaborations and partnerships to reach fragrance lovers worldwide. Our team is continuously innovating, experimenting with new blends, and refining our offerings to meet the dynamic demands of our customers. When you choose Mufa, you’re not just choosing a fragrance; you’re selecting a symbol of quality, elegance, and passion.
          </p>
        </div>

        {/* Third Section: Our Mission */}
        <div className="px-6 pt-4 pb-4 text-center max-w-full">
          <h2 className="text-lg text-gray-800 font-bold mb-4">Our Mission</h2>
          <p className="text-justify text-gray-700 leading-relaxed mb-4">
          Our mission at Mufa World is to redefine affordable luxury in the world of perfumery. We aim to make high-quality, luxurious fragrances accessible to everyone who appreciates sophistication without compromise. As we continue to grow, we remain focused on delivering memorable experiences and setting new standards in the fragrance industry through excellence, authenticity, and innovation.
          </p>
          <p className="text-justify text-gray-700 leading-relaxed mb-4">
          Discover Mufa – where luxury meets affordability, and every scent is a journey.
          </p>

          {/* Buttons */}
          <div className="flex justify-center mt-4">
            <Link to="/products">
              <button className="bg-black text-white px-4 py-2 rounded-md hover:bg-red-600 transition-all">
                Our Products
              </button>
            </Link>
            <Link to="/contact" className="ml-4">
              <button className="bg-gray-800 text-white px-4 py-2 rounded-md hover:bg-red-600 transition-all">
                Contact Us
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default About_UsPage;
