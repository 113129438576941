import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { FaInstagram } from "react-icons/fa"; // Import Instagram icon
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const InstagramFeed = () => {
    const [posts, setPosts] = useState([]);

    const accessToken =
        "IGQWROTUZA1MHpFZAkN4OG5NSk5RcVR3WTVydEtXaGhYZAEg4SVlfalExUFBPaDVPZAzR2MUltOTRIY0JONl9vRUJFMTJFdGdyTXlrcDBDY1JmcFpkV2VCYlV2UjBWWTFwMUZAiMS05MGhBeV84UEN4S3prZAW9Qd08tT2cZD";

    useEffect(() => {
        const fetchInstagramPosts = async () => {
            try {
                const response = await fetch(
                    `https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&access_token=${accessToken}`
                );
                const data = await response.json();
                setPosts(data.data);
            } catch (error) {
                console.error("Error fetching Instagram posts:", error);
            }
        };

        fetchInstagramPosts();
    }, []);

    const NextArrow = ({ onClick }) => (
        <div
            className="absolute top-1/2 right-4 transform -translate-y-1/2 z-10 cursor-pointer text-gray-700 bg-white rounded-full p-2 shadow-lg"
            onClick={onClick}
        >
            <ArrowForwardIosIcon />
        </div>
    );

    const PrevArrow = ({ onClick }) => (
        <div
            className="absolute top-1/2 left-4 transform -translate-y-1/2 z-10 cursor-pointer text-gray-700 bg-white rounded-full p-2 shadow-lg"
            onClick={onClick}
        >
            <ArrowBackIosIcon />
        </div>
    );

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div className="max-w-screen-xl mx-auto p-4 relative">
            {/* Heading with Instagram icon */}
            <h2 className="text-3xl font-bold text-center mb-6">
                <span className="instagram-gradient inline-block">
                    Instagram Feed
                </span>
                <FaInstagram className="inline-block ml-2 text-pink-600" size={30} />
            </h2>
            <Slider {...settings}>
                {posts.map((post) => (
                    <div key={post.id} className="p-2">
                        <div className="rounded-lg shadow-lg overflow-hidden">
                            {post.media_type === "IMAGE" || post.media_type === "CAROUSEL_ALBUM" ? (
                                <img
                                    src={post.media_url}
                                    alt={post.caption}
                                    className="w-full h-64 object-cover"
                                />
                            ) : post.media_type === "VIDEO" ? (
                                <video controls className="w-full h-64">
                                    <source src={post.media_url} type="video/mp4" />
                                </video>
                            ) : null}
                        </div>
                        {post.caption && (
                            <p className="mt-2 text-center text-sm text-gray-600">
                                {post.caption.substring(0, 50)}...
                            </p>
                        )}
                    </div>
                ))}
            </Slider>
            {/* CSS */}
            <style jsx>{`
                .instagram-gradient {
                    background: linear-gradient(
                        45deg,
                        #f09433,
                        #e6683c,
                        #dc2743,
                        #cc2366,
                        #bc1888
                    );
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                .slick-list {
                    margin-bottom: 0 !important;
                }
                .slick-slider {
                    margin-bottom: 0 !important;
                }
            `}</style>
        </div>
    );
};

export default InstagramFeed;
