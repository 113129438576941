import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import MetaData from "../component/layouts/MataData/MataData";
import ScrollToTopOnRefresh from "../component/Home/ScrollToTop";

const ContactForm = () => {
  const alert = useAlert();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    fullname: "",
    issue: "e-commerce",
    detail: "availability",
    email: "",
    message: "",
  });

  const [captchaQuestion, setCaptchaQuestion] = useState(""); // For storing captcha question
  const [captchaSolution, setCaptchaSolution] = useState(null); // For storing captcha answer
  const [captchaValue, setCaptchaValue] = useState(""); // For user's captcha input

  const [errors, setErrors] = useState({
    message: "", // Error message for message field
    captcha: "", // Error message for captcha field
    dropdown: "", // Error message for dropdown fields
  });

  // Generate math CAPTCHA
  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setCaptchaQuestion(`${num1} + ${num2} = ?`);
    setCaptchaSolution(num1 + num2); // Store the solution
  };

  // Run on component mount
  useEffect(() => {
    generateCaptcha(); // Generate CAPTCHA on component load
  }, []);

  const handleCall = () => {
    window.location.href = "tel:+91 8448 82 9359";
  };

  // Update form data state
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  // Handle CAPTCHA input change
  const handleCaptchaChange = (e) => {
    setCaptchaValue(e.target.value); // Update CAPTCHA value entered by the user
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset errors
    setErrors({ message: "", dropdown: "", captcha: "" });

    // Validate CAPTCHA before form submission
    if (parseInt(captchaValue) !== captchaSolution) {
      setErrors((prev) => ({ ...prev, captcha: "Incorrect CAPTCHA answer. Please try again." }));
      generateCaptcha(); // Regenerate CAPTCHA if wrong
      return;
    }

    // Ensure all form fields are filled before submission
    if (!formData.fullname || !formData.email || !formData.issue || !formData.detail) {
      setErrors((prev) => ({ ...prev, dropdown: "Please select the required fields." }));
      alert.error("Please fill in all required fields.");
      return;
    }

    // Validate message length (at least 10 characters)
    if (formData.message.length < 10) {
      setErrors((prev) => ({ ...prev, message: "Message must be at least 10 characters long." }));
      return;
    }

    try {
      const response = await fetch("http://localhost:5000/api/v1/contact/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        alert.success("Your message has been sent successfully");
        navigate("/"); // Redirect after successful submission
      } else {
        // Check if the response contains a specific error message
        alert.error(data.message || "There was an error sending your message. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert.error("An error occurred. Please try again.");
    }
  };

  return (
    <>
  <ScrollToTopOnRefresh />
        <MetaData 
        title="Contact Us - Mufa Perfumes | Get in Touch for Queries or Support" 
        description="Have a question or need support? Contact Mufa Perfumes today for customer service, product inquiries, and any assistance you need with your fragrance journey." 
        keywords="contact Mufa Perfumes, customer support, fragrance inquiries, contact us, perfume support"
        ogImage="%PUBLIC_URL%/path-to-contact-us-image.jpg"
        ogUrl="https://mufaworld.com/contact"
        canonicalUrl="https://mufaworld.com/contact"
      />

      <div className="bg-[#001c25] text-white py-28 px-8">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold text-center mb-6 animate-fade-in">
            Contact Us
          </h1>
          <p className="text-center text-[#dec27e] mb-10">
            Need assistance or have any questions? Our team is here to help you!
          </p>

          {/* Contact Info */}
          <div className="text-center mb-10">
            <p><strong>Mufa International Pvt Ltd.</strong></p>
            <p>Unit No-500, Plot No. B-9, ITL Twin Tower, 5th Floor, NSP, Pitam Pura, New Delhi, 110034, India</p>
            <p><strong>Email:</strong> <a href="mailto:mufaworld@gmail.com" className="text-[#dec27e] hover:underline">mufaworld@gmail.com</a></p>
            <p><strong>Phone:</strong> <span onClick={handleCall} className="text-[#dec27e] cursor-pointer hover:underline">+91 8448 82 9359</span>
            <br/>
              <span>We have live chat available, look for the chat icon in the lower right hand corner of this page. If it isn’t there, then give us a call at</span>
            
            </p>
          </div>

          {/* Additional Content */}
          <div className="bg-[#dec27e] text-black p-6 rounded-lg shadow-md mb-12">
            <h2 className="text-3xl font-semibold text-center mb-4 text-[#000]">
              Why Choose Mufa International?
            </h2>
            <p className="text-lg mb-4 text-[#001c25]">
              At Mufa International, we offer personalized support to cater to
              all your needs. Whether you have questions about our products,
              services, or need assistance with an order, our team is just a
              message away. Our support is backed by years of expertise in the
              industry, and we strive to offer the best experience to our
              customers.
            </p>
            
          </div>

          <div className="bg-[#dec27e] text-black p-6 rounded-lg shadow-md mb-12">
            <div>
    <p className="text-lg mb-4 text-[#001c25]">
    At Mufa Perfume, we offer a premium collection of fragrances, blending quality, innovation, and sophistication. From fresh floral to deep oriental notes, our range caters to every preference.    </p>
    <p className="text-lg text-[#001c25]">
    We focus on providing a personalized experience, guiding you to find the perfect scent that expresses your individuality from discovery to purchase.
</p>
  </div>
          </div>

          <div id="support-form" className="bg-white text-black p-4 rounded-lg shadow-md animate-slide-in max-w-xl mx-auto">
            <h2 className="text-2xl font-bold text-center mb-4">Support Form</h2>

            <form onSubmit={handleSubmit} className="space-y-4">
              {/* Full Name */}
              <div className="relative">
                <label htmlFor="fullname" className="block font-medium mb-1 text-[#001c25]">Full Name *</label>
                <input
                  type="text"
                  id="fullname"
                  value={formData.fullname}
                  onChange={handleChange}
                  placeholder="Enter Your Full Name"
                  className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-[#dec27e]"
                  required
                />
              </div>

              {/* Issue Dropdown */}
              <div className="relative">
                <label htmlFor="issue" className="block font-medium mb-1 text-[#001c25]">Issue *</label>
                <select
                  id="issue"
                  value={formData.issue}
                  onChange={handleChange}
                  className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-[#dec27e]"
                >
                  <option value="e-commerce">E-Commerce</option>
                  <option value="app">App</option>
                </select>
              </div>

              {/* Detail Dropdown */}
              <div className="relative">
                <label htmlFor="detail" className="block font-medium mb-1 text-[#001c25]">Detail *</label>
                <select
                  id="detail"
                  value={formData.detail}
                  onChange={handleChange}
                  className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-[#dec27e]"
                >
                  <option value="availability">Availability</option>
                  <option value="return/exchange">Return/Exchange</option>
                  <option value="technical-support">Technical Support</option>
                  <option value="invoicing">Invoicing</option>
                  <option value="tracking-info">Tracking Info</option>
                  <option value="others">Others</option>
                </select>
              </div>

              {/* Email */}
              <div className="relative">
                <label htmlFor="email" className="block font-medium mb-1 text-[#001c25]">Email *</label>
                <input
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter Your Email"
                  className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-[#dec27e]"
                  required
                />
              </div>

              {/* Message */}
              <div className="relative">
                <label htmlFor="message" className="block font-medium mb-1 text-[#001c25]">Message *</label>
                <textarea
                  id="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="3"
                  placeholder="Enter Your Message"
                  className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-[#dec27e]"
                  required
                ></textarea>
                {/* Error message for message field */}
                {errors.message && (
                  <span className="text-red-600 text-sm absolute right-2 bottom-2">{errors.message}</span>
                )}
              </div>

           {/* CAPTCHA */}
           <div className="relative">
                <label>{captchaQuestion}</label>
                <input
                  type="text"
                  value={captchaValue}
                  onChange={handleCaptchaChange}
                  placeholder="Enter CAPTCHA"
                  className="w-full border border-gray-300 rounded-md p-2 mt-2 focus:outline-none focus:ring-2 focus:ring-[#dec27e]"
                  required
                />
                {/* Error message for CAPTCHA */}
                {errors.captcha && (
                  <span className="text-red-600 text-sm absolute right-2 bottom-2">{errors.captcha}</span>
                )}
              </div>

              {/* Error message for dropdown fields */}
              {errors.dropdown && (
                <div className="text-red-600 text-center mb-4">
                  <strong>{errors.dropdown}</strong>
                </div>
              )}

              <button type="submit" className="w-full p-2 mt-4 bg-[#a3812d] text-white rounded-md hover:bg-[#001c25]">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
