import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Rating from "@material-ui/lab/Rating";
import { useSelector, useDispatch } from "react-redux";
import { clearErrors, getProductDetails } from "../../actions/productAction";
import { useAlert } from "react-alert";
import MetaData from "../layouts/MataData/MataData";
import CricketBallLoader from "../layouts/loader/Loader";
import Button from "@mui/material/Button";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import ReviewCard from "./ReviewCard";
import "./ProductDetails.css";
import { addItemToCart } from "../../actions/cartAction";
import { displayMoney } from "../DisplayMoney/DisplayMoney";
import ScrollToTopOnRefresh from "../Home/ScrollToTop";



const ProductDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const alert = useAlert();

  const [quantity, setQuantity] = useState(1);
  const [previewImg, setPreviewImg] = useState("");
  const [activeThumbnail, setActiveThumbnail] = useState(0);

  const { product, loading, error } = useSelector((state) => state.productDetails);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getProductDetails(id));
  }, [dispatch, id, error, alert]);

  useEffect(() => {
    if (product && product.images) {
      setPreviewImg(product.images[0]?.url || "");
      setActiveThumbnail(0);
    }
  }, [product]);

  const handlePreviewImg = (url, index) => {
    setPreviewImg(url);
    setActiveThumbnail(index);
  };

  const increaseQuantityHandler = () => {
    if (product.Stock > quantity) setQuantity(quantity + 1);
  };

  const decreaseQuantityHandler = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };

  const handleAddItem = () => {
    dispatch(addItemToCart(id, quantity));
    alert.success("Item Added To Cart");
  };

  // Calculating Prices
  const originalPrice = product.price || 0; // Fallback to 0 if price is not defined
  const discountPrice = product.DiscountPrice || originalPrice; // Fallback to original price if discount price is not defined

  // Calculating the amount saved and the final price after discount
  const savedPrice = originalPrice - discountPrice;
  const savedDiscount = Math.round((savedPrice / originalPrice) * 100);

  const newPrice = displayMoney(discountPrice); // Discounted price to show
  const oldPrice = displayMoney(originalPrice); // Original price (crossed out)
  const savedAmount = displayMoney(savedPrice); // Amount saved

  return (
    <>
    <ScrollToTopOnRefresh />
      {loading ? (
        <CricketBallLoader />
      ) : (
        <div className="productDetailsContainer">
          <MetaData
            title={`${product.name} - Buy Online at Best Prices`}
            description={`Shop ${product.name} online at the best price. ${product.description || ""} Save ${savedAmount} (${savedDiscount}%) now!`}
            keywords={`${product.name}, buy ${product.name}, ${product.category}`}
            image={previewImg}
            url={`https://yourwebsite.com/product/${id}`}
            canonical={`https://yourwebsite.com/product/${id}`}
          />
          <section id="product_details" className="section">
            <div className="product_container">
              <div className="wrapper prod_details_wrapper">
                <div className="prod_details_left_col">
                  <div className="prod_details_tabs">
                    {product?.images &&
                      product.images.map((img, i) => (
                        <div
                          key={i}
                          className={`tabs_item ${activeThumbnail === i ? "active" : ""}`}
                          onClick={() => handlePreviewImg(img.url, i)}
                        >
                          <img src={img.url} alt={`product-thumbnail-${i}`} />
                        </div>
                      ))}
                  </div>
                  <figure className="product_details_img">
                    <img src={previewImg} alt="product-main" />
                  </figure>
                </div>

                <div className="prod_details_right_col_001">
                  <h1 className="prod_details_title">{product.name}</h1>
                  <h4 className="prod_details_info">
                    {product.info || "No Information Available"}
                  </h4>

                  <div className="prod_details_ratings">
                    <Rating
                      value={product.ratings}
                      precision={0.5}
                      readOnly
                      style={{ color: "black", fontSize: 16 }}
                    />
                    <span>|</span>
                    <Link to="#" style={{ textDecoration: "none", color: "#414141" }}>
                      {product.numOfReviews} Ratings
                    </Link>
                  </div>

                  <div className="prod_details_price">
                    <div className="price_box">
                      <h2 className="price">
                        {newPrice} &nbsp;
                        <small className="del_price">
                          <del>{oldPrice}</del>
                        </small>
                      </h2>
                      <p className="saved_price">
                        You save: {savedAmount} ({savedDiscount}%)
                      </p>
                      <span className="tax_txt">
                        (Inclusive of all taxes)
                      </span>
                    </div>

                    <div className="badge">
                      {product.Stock >= 1 ? (
                        <span className="instock">
                          <DoneIcon /> In Stock
                        </span>
                      ) : (
                        <span className="outofstock">
                          <CloseIcon />
                          Out of stock
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="separator"></div>

                  <div className="productDescription">
                    <div className="productDescription_text">
                      <h4>Description:</h4>
                      <p>{product.description || "No description available"}</p>
                    </div>
                    <div className="prod_details_offers">
                      <h4>Offers and Discounts</h4>
                      <ul>
                        <li>No Cost EMI on Credit Card</li>
                        <li>Pay Later & Avail Cashback</li>
                      </ul>
                    </div>
                    <div className="deliveryText">
                      <LocalShippingOutlinedIcon />
                      We deliver! Just say when and how.
                    </div>
                  </div>
                  <div className="separator"></div>

                  <div className="prod_details_additem">
                    <h5>QTY:</h5>
                    <div className="additem">
                      <IconButton onClick={decreaseQuantityHandler} className="additem_decrease">
                        <RemoveIcon />
                      </IconButton>
                      <Input readOnly type="number" value={quantity} className="input" />
                      <IconButton onClick={increaseQuantityHandler} className="additem_increase">
                        <AddIcon />
                      </IconButton>
                    </div>

                    <Button
                      variant="contained"
                      className="prod_details_addtocart_btn"
                      onClick={handleAddItem}
                      disabled={product.Stock <= 0}
                    >
                      Add to cart
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="reviewCard">
            <ReviewCard product={product} />
          </div>
        </div>
      )}
    </>
  );
};

export default ProductDetails;
