import React, { useState } from "react";
import "./Shipping.css";
import { useSelector, useDispatch } from "react-redux";
import { saveShippingInfo } from '../../actions/cartAction';
import MetaData from "../layouts/MataData/MataData";
import CheckoutSteps from "./CheckoutSteps ";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";

import {
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Custom styles for Material-UI components
const useStyles = makeStyles((theme) => ({
  shippingRoot: {
    width: "60%",
    margin: "auto",
  },
  heading: {
    marginBottom: theme.spacing(2),
    alignSelf: "flex-start",
  },
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 200,
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "50%",
    backgroundColor: "#000000",
    color: "#FFFFFF",
    height: "3rem",
    "&:hover": {
      backgroundColor: "#ed1c24",
      color: "#FFFFFF",
    },
  },
  outlinedInput: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#000000",
      },
      "&:hover fieldset": {
        borderColor: "#000000", 
      },
      "&.Mui-focused fieldset": {
        borderColor: "#000000", 
      },
    },
    "& .MuiInputBase-input": {
      color: "#000000", 
    },
    "& .MuiInputLabel-root": {
      color: "#000000", 
    },
  },
}));

const Shipping = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Fetching the existing shipping info from the Redux store
  const { shippingInfo } = useSelector((state) => state.cart);

  // Form input states
  const [address, setAddress] = useState(shippingInfo.address || "");
  const [firstName, setFirstName] = useState(shippingInfo.firstName || "");
  const [lastName, setLastName] = useState(shippingInfo.lastName || "");
  const [city, setCity] = useState(shippingInfo.city || "");
  const [pinCode, setPinCode] = useState(shippingInfo.pinCode || "");
  const [state, setState] = useState(shippingInfo.state || "");
  const [country, setCountry] = useState(shippingInfo.country || "India");
  const [phoneNo, setPhone] = useState(shippingInfo.phoneNo || "");
  const [email, setEmail] = useState(shippingInfo.email || "");
  const [saveAddress, setSaveAddress] = useState(false);
  const [sameBillingDelivery, setSameBillingDelivery] = useState(false);
  
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isPhoneNoValid, setIsPhoneNoValid] = useState(true);

  const classes = useStyles();

  // Form validation and handling
  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate the fields
    if (
      email === "" ||
      firstName === "" ||
      lastName === "" ||
      address === "" ||
      city === "" ||
      state === "" ||
      country === "" ||
      pinCode === "" ||
      phoneNo === ""
    ) {
      alert.error("Please fill all the fields");
      return;
    }

    if (phoneNo && phoneNo.length !== 10) {
      alert.error("Phone Number should be 10 digits long");
      return;
    }

    // Save shipping info in the Redux store
    dispatch(
      saveShippingInfo({
        address,
        city,
        state,
        country,
        pinCode,
        phoneNo,
        email,
        firstName,
        lastName,
      })
    );

    // Navigate to payment page
    navigate("/process/payment");
  };

  return (
    <>
      <div className="shippingPage">
        <MetaData title={"Shipping Info"} />
        <CheckoutSteps activeStep={1} />

        <div className="shippingPage__container">
          <div className="shippingPage__container__left">
            <div className={classes.shippingRoot}>
              <form onSubmit={handleSubmit}>
                <Typography variant="h6" className={classes.heading}>
                  SHIPPING ADDRESS
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className={classes.outlinedInput}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Last Name"
                      variant="outlined"
                      fullWidth
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className={classes.outlinedInput}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Address"
                      variant="outlined"
                      fullWidth
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className={classes.outlinedInput}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="City"
                      variant="outlined"
                      fullWidth
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      className={classes.outlinedInput}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Pincode"
                      variant="outlined"
                      fullWidth
                      value={pinCode}
                      onChange={(e) => setPinCode(e.target.value)}
                      className={classes.outlinedInput}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="State"
                      variant="outlined"
                      fullWidth
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      className={classes.outlinedInput}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Country"
                      variant="outlined"
                      fullWidth
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      className={classes.outlinedInput}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Phone"
                      variant="outlined"
                      fullWidth
                      value={phoneNo}
                      onChange={(e) => setPhone(e.target.value)}
                      className={classes.outlinedInput}
                      error={!isPhoneNoValid && phoneNo !== ""}
                      helperText={
                        !isPhoneNoValid && phoneNo && "Please enter a valid phone number."
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      fullWidth
                      value={email}
                      onChange={(e) => {
                        const newEmail = e.target.value;
                        setEmail(newEmail);
                        setIsValidEmail(
                          newEmail === "" || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail)
                        );
                      }}
                      className={classes.outlinedInput}
                      error={!isValidEmail && email !== ""}
                      helperText={
                        !isValidEmail && email && "Please enter a valid email address."
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveAddress}
                          style={{ color: "#000000" }}
                          onChange={(e) => setSaveAddress(e.target.checked)}
                        />
                      }
                      label="Save Address to Address Book"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sameBillingDelivery}
                          style={{ color: "#000000" }}
                          onChange={(e) => setSameBillingDelivery(e.target.checked)}
                        />
                      }
                      label="My billing and delivery information are the same."
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submitButton}
                    >
                      Continue
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shipping;
