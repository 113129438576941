import React from 'react';
import { Helmet } from 'react-helmet';

function MetaData({ 
  title, 
  description, 
  keywords, 
  ogImage, 
  ogUrl, 
  canonicalUrl 
}) {

  // Structured Data (JSON-LD for Organization)
  const structuredData = {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "Mufa Perfumes",
    "url": "https://mufaworld.com",
    "logo": "https://mufaworld.com/logo.png",
    "sameAs": [
      "https://www.facebook.com/mufaperfumes",
      "https://twitter.com/mufaperfumes",
      "https://www.instagram.com/mufaperfumes/",
    ],
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+91 8448829359",
      "email": "mufaworld@gmail.com",
      "contactType": "Customer Service",
      "areaServed": "IN",
      "availableLanguage": "English"
    }
  };

  return (
    <Helmet>
      {/* Dynamic Title */}
      <title>{title}</title>

      {/* Meta Description */}
      <meta name="description" content={description || "Default description here"} />

      {/* Meta Keywords */}
      <meta name="keywords" content={keywords || "Default, Keywords, Here"} />

      {/* Robots Meta Tag */}
      <meta name="robots" content="index, follow" />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description || "Default description here"} />
      <meta property="og:image" content={ogImage || "%PUBLIC_URL%/default-image.jpg"} />
      <meta property="og:url" content={ogUrl || "https://mufaworld.com/"} />
      <meta property="og:type" content="website" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description || "Default description here"} />
      <meta name="twitter:image" content={ogImage || "%PUBLIC_URL%/default-image.jpg"} />

      {/* Add Canonical Tag */}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

      {/* Structured Data Markup (JSON-LD) */}
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
}

export default MetaData;
