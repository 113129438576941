import React from "react";
import LoaderMufa from "../../../Image/Loader-svg/LoaderMufa.svg"; // Use as a normal image
import "./Loader.css";

const CricketBallLoader = () => (
  <div className="cricket-ball-loader">
    <img src={LoaderMufa} alt="Loading..." className="spinner" />
  </div>
);

export default CricketBallLoader;
