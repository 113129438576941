const initialState = {
  blogs: [], // Ensure blogs is initialized as an empty array
  blog: {},  // Store the details of a single blog
  loading: false,
  error: null,
};

const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    // Fetch Blogs
    case "FETCH_BLOGS_REQUEST":
      return { ...state, loading: true, error: null };
    case "FETCH_BLOGS_SUCCESS":
      return { ...state, loading: false, blogs: action.payload };
    case "FETCH_BLOGS_FAILURE":
      return { ...state, loading: false, error: action.payload };

    // Create Blog
    case "CREATE_BLOG_REQUEST":
      return { ...state, loading: true, error: null };
    case "CREATE_BLOG_SUCCESS":
      return {
        ...state,
        loading: false,
        blogs: [action.payload, ...state.blogs], // Add the new blog to the top of the list
      };
    case "CREATE_BLOG_FAILURE":
      return { ...state, loading: false, error: action.payload };

    // Delete Blog
    case "DELETE_BLOG_REQUEST":
      return { ...state, loading: true, error: null };
    case "DELETE_BLOG_SUCCESS":
      return {
        ...state,
        loading: false,
        blogs: state.blogs.filter((blog) => blog._id !== action.payload), // Remove the deleted blog
      };
    case "DELETE_BLOG_FAILURE":
      return { ...state, loading: false, error: action.payload };

    // Update Blog
    case "UPDATE_BLOG_REQUEST":
      return { ...state, loading: true, error: null };
    case "UPDATE_BLOG_SUCCESS":
      return {
        ...state,
        loading: false,
        blogs: state.blogs.map((blog) =>
          blog._id === action.payload._id ? action.payload : blog
        ), // Update the blog in the list
      };
    case "UPDATE_BLOG_FAILURE":
      return { ...state, loading: false, error: action.payload };

     // Fetch Single Blog Details
     case "FETCH_BLOG_DETAILS_REQUEST":
      return { ...state, loading: true, error: null };
    case "FETCH_BLOG_DETAILS_SUCCESS":
      return { ...state, loading: false, blog: action.payload }; // Store the single blog's details
    case "FETCH_BLOG_DETAILS_FAILURE":
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
      
  }
};

export default blogReducer;
