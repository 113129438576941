import React, { useState } from "react";
import { Button, Divider } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { addItemToCart } from "../../actions/cartAction";
import { useNavigate } from "react-router-dom";
import DialogBox from "../Product/DialogBox";

// Format order date
const createdAt = (user) => {
  const createdAt = new Date(user.createdAt);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "Asia/Kolkata",
  };

  const formatter = new Intl.DateTimeFormat("en-IN", options);
  return formatter.format(createdAt);
};

const OrderCard = ({ item, user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const [open, setOpen] = useState(false);

  const { shippingInfo, orderItems } = item;

  const addToCartHandler = (id, qty = 0) => {
    dispatch(addItemToCart(id, qty));
    alert.success("Item Added to Cart");
    navigate("/cart");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Sort orders by date - Newest orders first
  const sortedOrderItems = [...orderItems].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  return (
    <div className="space-y-8 mt-4">
      {sortedOrderItems.map((product, index) => (
        <div
          key={index}
          className="bg-white shadow-lg rounded-lg p-4 flex flex-col lg:flex-row justify-between space-y-4 lg:space-y-0"
        >
          {/* Order Details */}
          <div className="w-full lg:w-2/3 flex flex-col space-y-4">
            {/* Order Header */}
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-lg font-semibold text-gray-700">ORDER PLACED</h2>
                <p className="text-sm text-gray-600">{createdAt(item)}</p>
                <p className="text-sm font-medium text-gray-700">ORDER-ID: #{item._id}</p>
              </div>
            </div>

            {/* Product Info */}
            <div className="flex items-start space-x-4">
              <img
                src={product.image}
                alt={product.name}
                className="w-24 h-24 object-cover rounded-lg"
              />
              <div className="flex flex-col justify-between flex-grow">
                <h3 className="text-lg font-semibold text-gray-800">{product.name}</h3>
                <p className="text-sm text-gray-600">
                  <strong>QTY:</strong> {product.quantity}
                </p>
                <p className="text-sm">
                  <strong>Delivery Status:</strong>{" "}
                  <span
                    className={item.orderStatus === "Delivered" ? "text-green-600" : "text-red-600"}
                  >
                    {item.orderStatus}
                  </span>
                </p>

                <div className="flex space-x-2 mt-2">
                  <Button
                    variant="outlined"
                    className="text-sm border-gray-500 w-fit flex items-center space-x-1"
                    onClick={() => addToCartHandler(product.productId, 1)}
                  >
                    <ReplayIcon className="mr-1" />
                    Buy Again
                  </Button>
                  <Button
                    variant="outlined"
                    className="text-sm border-gray-500 w-fit"
                    onClick={() => navigate(`/product/${product.productId}`)}
                  >
                    View item
                  </Button>
                </div>
              </div>
            </div>

            <Divider className="my-2" />

            <Button
              variant="outlined"
              style={{ marginLeft: "115px" }}
              className="border-gray-500 text-sm flex items-center space-x-2 w-fit ml-36"
              onClick={handleClickOpen}
            >
              <EditIcon className="mr-1" />
              Write A Product Review
            </Button>

            <DialogBox
              open={open}
              handleClose={handleClose}
              id={product.productId}
            />
          </div>

          {/* Address & Total Price (visible only on larger screens) */}
          <div className="w-full lg:w-1/3 space-y-4">
            <div className="hidden lg:block">
              <h3 className="font-semibold text-gray-700">Total:</h3>
              <p className="font-medium text-gray-900">₹ {product.price * product.quantity}</p>
            </div>
            <div className="space-y-2">
              <h3 className="text-lg font-semibold text-gray-800">{user.name}</h3>
              <p className="text-sm text-gray-600">Delivery Address:</p>
              <p className="text-sm text-gray-700">{shippingInfo.address}</p>
              <p className="text-sm text-gray-700">
                {shippingInfo.city}, {shippingInfo.state}, {shippingInfo.country} - {shippingInfo.pinCode}
              </p>
              <p className="text-sm text-gray-700">Phone: {shippingInfo.phoneNo}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OrderCard;
