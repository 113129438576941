// src/TestimonialCard.js
import React from 'react';

const TestimonialsStar = ({ testimonial }) => {
  // Function to render stars based on the rating value
  const renderStars = (rating) => {
    let stars = '';
    for (let i = 1; i <= 5; i++) {
      stars += i <= rating ? '★' : '☆'; // Filled star for rating, empty star for non-rating
    }
    return stars;
  };

  return (
    <div className="testimonial-card">
      <div className="stars">{renderStars(testimonial.rating)}</div>
      <p>{testimonial.text}</p>
      <div className="testimonial-author bg-[#dec27e]">
        <img src={testimonial.image} alt={testimonial.name} />
        <div>
          <h3>{testimonial.name}</h3>
          <p>{testimonial.title}</p>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsStar;
