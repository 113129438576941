import React from 'react';
import Slider from 'react-slick';
import TestimonialCard from './TestimonialsStar'; // Correct import for TestimonialCard component

import avtar1 from '../../Image/Testimonials/avtar1.webp';
import avtar2 from '../../Image/Testimonials/avtar2.webp';
import profileImage from '../../Image/Testimonials/Profile.png';

const testimonialsData = [
  {
    name: "MUFA Sophia",
    title: "Arohi Thakral",
    text: "Sophia offers an enchanting fragrance, balancing freshness and richness perfectly. Its long-lasting scent makes every moment memorable.",
    image: avtar1, // Using avtar1.webp
    rating: 5,
  },
  {
    name: "MUFA Oud Sora",
    title: "Munawar",
    text: "Oud Sora captivates with its deep, woody notes. The fragrance lingers beautifully, making it truly unforgettable.",
    image: avtar2, // Using avtar2.webp
    rating: 4,
  },
  {
    name: "Black Mystic",
    title: "Wasim",
    text: "Black Mystic is bold and mysterious, perfect for leaving a lasting impression. Its aroma elevates any occasion.",
    image: profileImage, // Using Profile.png
    rating: 3,
  },
  {
    name: "Dazzle Deodorant",
    title: "Mohan Singh",
    text: "Dazzle deodorant provides superior odor control. The elegant scent keeps me confident and dry throughout my busy day.",
    image: avtar1, // Reusing avtar1.webp
    rating: 5,
  },
  {
    name: "MUFA Ripple",
    title: "Haider Ali",
    text: "Ripple's fresh and uplifting fragrance is perfect for daily wear. It brightens my mood and keeps me refreshed.",
    image: avtar2, // Reusing avtar2.webp
    rating: 2,
  },
  {
    name: "MUFA Zephyr",
    title: "Unknown",
    text: "Zephyr is light and airy, offering a subtle yet captivating scent. Perfect for casual outings or a relaxed day.",
    image: profileImage, // Reusing Profile.png
    rating: 5,
  },
  {
    name: "MUFA Mellow",
    title: "Rohit Kumar",
    text: "Mellow has a warm, inviting fragrance. It’s perfect for both day and night, making it a versatile choice.",
    image: avtar1, // Reusing avtar1.webp
    rating: 5,
  },
  {
    name: "Allure Deodorant",
    title: "Shivam Nishad",
    text: "Allure deodorant is my go-to for long-lasting protection. The scent is elegant and keeps me fresh all day.",
    image: avtar2, // Reusing avtar2.webp
    rating: 5,
  },
  {
    name: "Fragrance Combo",
    title: "Sunil Sharma",
    text: "This combo combines fragrances beautifully. Each product complements the others, enhancing my daily scent experience.",
    image: profileImage, // Reusing Profile.png
    rating: 4,
  },
  {
    name: "Elegant Perfume",
    title: "Abhishek Kumar",
    text: "The perfume oil offers a rich, long-lasting scent with every drop. A luxurious experience throughout the day.",
    image: avtar1, // Reusing avtar1.webp
    rating: 5,
  },
  {
    name: "Oud Perfume",
    title: "Jitendra Thakur",
    text: "This Oud perfume oil delivers an exotic aroma that lingers. Perfect for special occasions or evening wear.",
    image: avtar1,
    rating: 3.5,
  },
  {
    name: "Seductive Parfum",
    title: "Abhinav Khanna",
    text: "The seductive parfum is ideal for unforgettable impressions. Its deep notes create an alluring aura effortlessly.",
    image: avtar2,
    rating: 4.5,
  },
  {
    name: "Deodorant Spray",
    title: "Zahir Ahmad",
    text: "The deodorant spray provides long-lasting protection, keeping me confident and dry, no matter how hectic my day.",
    image: profileImage,
    rating: 5,
  },
  {
    name: "Men’s Fragrance",
    title: "Azad Ali",
    text: "This men’s fragrance combines rich notes that leave a lasting impression. Perfect for any occasion.",
    image: avtar2,
    rating: 5,
  },
  {
    name: "Unisex Perfume",
    title: "Rahul Dey",
    text: "The unisex perfume balances elegance and freshness. Its versatile scent makes it perfect for anyone, anytime.",
    image: avtar1,
    rating: 3,
  },
  {
    name: "Blossom Perfume",
    title: "Rihana Khaan",
    text: "Blossom Perfume is perfect for those who love light, floral fragrances. Its soft, elegant scent is ideal for daily wear.",
    image: avtar2,
    rating: 5,
  },
  {
    name: "Passion Mist",
    title: "Unknow",
    text: "Passion Mist offers a playful, fruity fragrance. It’s perfect for adding a touch of fun to any occasion.Lovely Nice!!",
    image: profileImage,
    rating: 5,
  },
  {
    name: "MUFA Amber Glow",
    title: "Anup Goud",
    text: "Amber Glow provides a warm and spicy fragrance that wraps you in comfort. It’s perfect for cozy nights.",
    image: avtar2,
    rating: 4,
  },
  {
    name: "Ocean Breeze",
    title: "Ramesh Kumar",
    text: "Ocean Breeze offers a fresh and invigorating scent, reminding me of relaxing by the sea. Ideal for daily freshness.",
    image: profileImage,
    rating: 4,
  },
  {
    name: "MUFA Musk Noir",
    title: "Sudhir Nishad",
    text: "Musk Noir delivers a rich, mysterious fragrance. Its deep musk notes make it perfect for evening events.",
    image: avtar1,
    rating: 3,
  }
];

const TestimonialsSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,        // Transition speed of the slide animation
    autoplay: true,    // Enables automatic slide
    autoplaySpeed: 2000, // Delay between automatic slides (set to 2 seconds)
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,     // Enable swipe-to-slide functionality
          touchMove: true,  
        },
      },
    ],
  };

  return (
    <div className="testimonials-section bg-[#001c25] py-40">
      <h2 style={{ fontWeight: 400, fontSize: '3rem', fontFamily: 'Abril Fatface', color: '#dec27e' }}>TESTIMONIALS</h2>
      <Slider className="testimonials" {...settings}>
        {testimonialsData.map((testimonial, index) => (
          <TestimonialCard key={index} testimonial={testimonial} />
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialsSection;
