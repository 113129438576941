import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { login, clearErrors } from "../../actions/userAction";
import CricketBallLoader from "../layouts/loader/Loader";
import { useAlert } from "react-alert";
import MetaData from "../layouts/MataData/MataData";
import { Button } from "@mui/material"; // Import Material-UI Button
import Visibility from "@mui/icons-material/Visibility"; // Import Visibility icon
import VisibilityOff from "@mui/icons-material/VisibilityOff"; // Import VisibilityOff icon

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const alert = useAlert();

  const { isAuthenticated, loading, error } = useSelector(
    (state) => state.userData
  );

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [loginError, setLoginError] = useState("");

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setIsValidEmail(
      newEmail !== "" && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail)
    );
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const isSignInDisabled = !(email && password && isValidEmail);

  const redirect = location.search
    ? location.search.split("=")[1]
    : "/account";

  useEffect(() => {
    if (error) {
      setLoginError("");
      dispatch(clearErrors());
    }

    if (isAuthenticated) {
      navigate(redirect);
    }
  }, [dispatch, isAuthenticated, loading, error, alert, navigate, redirect]);

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    setLoginError(""); // Reset error message before attempting login
    dispatch(login(email, password));
  };

  return (
    <>
      <MetaData title={"Login"} />
      {loading ? (
        <CricketBallLoader />
      ) : (
        <div
          className="flex items-center justify-center min-h-screen bg-[#001c25] px-4"
          style={{ marginTop: "96px", paddingTop: "20px" }}
        >
          <form className="bg-white p-8 rounded-lg shadow-lg w-full max-w-sm overflow-hidden">
            <div className="flex items-center justify-center mb-4">
              <div className="w-16 h-16 rounded-full bg-gold flex items-center justify-center">
                <span className="text-white text-2xl">🔒</span> {/* Icon placeholder */}
              </div>
            </div>
            <h1 className="text-center text-2xl font-bold mb-4 text-gold">
              Sign in to Your Account
            </h1>
            
            {/* Email Input */}
            <input
              type="email"
              placeholder="Email"
              className={`w-full p-3 mb-5 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gold ${
                !isValidEmail && email !== "" ? "border-red-500" : ""
              }`}
              value={email}
              onChange={handleEmailChange}
            />
            
            {/* Invalid Credentials Message */}
            {loginError && (
              <p className="text-red-500 text-center mb-2">
                {loginError}
              </p>
            )}
            
            {/* Password Input */}
            <div className="relative mb-5">
              <input
                type={showPassword ? "text" : "password"}  // Conditionally switch input type
                placeholder="Password"
                className={`w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gold ${
                  loginError !== "" ? "border-red-500" : ""
                }`}
                value={password}
                onChange={handlePasswordChange}
              />

              {/* <Button
                variant="outlined"
                className="absolute right-3 top-1/2 transform -translate-y-1/2 p-1 min-w-min"
                onClick={handleShowPasswordClick}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </Button> */}
            </div>

            {/* Remember Me and Forgot Password */}
            <div className="flex justify-between items-center mb-4">
              <label className="flex items-center">
                <input type="checkbox" className="mr-2" />
                Remember me
              </label>
              <Link to="/password/forgot" className="text-gold hover:underline">
                Forgot your password?
              </Link>
            </div>

            {/* Privacy Policy */}
            <p className="text-sm text-gray-600 mb-4">
              I accept the Mufa Perfumes Terms of Use and acknowledge that my
              information will be used in accordance with its
              <Link to="/policy/privacy" className="text-gold hover:underline">
                {" "}Privacy Policy.
              </Link>
            </p>
            
            {/* Sign In Button */}
            <button
              type="button"
              className={`w-full p-3 mb-4 text-white bg-[#bf8610] hover:bg-[#001c25] transition-colors ${
                isSignInDisabled ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isSignInDisabled}
              onClick={handleLoginSubmit}
            >
              Sign in
            </button>

            {/* Sign Up Link */}
            <p className="text-center">
              Don't have an account?{" "}
              <Link to="/signup" className="text-gold hover:underline">
                Create Account
              </Link>
            </p>
          </form>
        </div>
      )}
    </>
  );
}
