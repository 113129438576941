import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Input,
  Button,
} from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { displayMoney } from "../DisplayMoney/DisplayMoney";

const useStyles = makeStyles((theme) => ({
  root11: {
    display: "flex",
    alignItems: "center",
    padding: "1rem",
    width: "100%",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
    margin: "1rem 0",
    borderRadius: "8px",
    border: "1px solid #ddd",
    backgroundColor: "#fff",
    [theme.breakpoints.down(899)]: {
      padding: "0.5rem",
      margin: "0.5rem 0",
    },
  },
  media: {
    width: "200px",
    height: "240px",
    marginRight: "16px",
    [theme.breakpoints.down(699)]: {
      width: "35%",
    },
    [theme.breakpoints.down(499)]: {
      width: "25%",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
  },
  cartHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: "1rem",
    fontWeight: 600,
    marginRight: "1rem",
  },
  cartDeleteIcon: {
    color: "black",
    "&:hover": {
      color: "#ed1c24",
    },
  },
  priceItem: {
    display: "flex",
    alignItems: "baseline",
    marginLeft: "1.2rem",
  },
  cartSubHeadings: {
    fontSize: "16px",
    fontWeight: 500,
    textTransform: "uppercase",
    color: "#414141",
  },
  itemPrice: {
    fontSize: "16px",
    fontWeight: 400,
  },
  itemOldPrice: {
    marginLeft: "8px",
    fontSize: "14px",
    fontWeight: 400,
    textDecoration: "line-through",
    color: "gray",
  },
  contentBottom: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1rem",
  },
  button: {
    backgroundColor: "#ed1c24",
    color: "#fff",
    borderRadius: "4px",
    padding: "0.5rem 1rem",
    marginTop: "1rem",
    '&:hover': {
      backgroundColor: "#c8102e",
    },
  },
}));

function CartItem({
  deleteCartItems,
  item,
  decreaseQuantity,
  increaseQuantity,
  length,
}) {
  const classes = useStyles();

  // Correct price and discount handling from the database
  const finalPrice = displayMoney(item.DiscountPrice); // Display discounted price
  const oldPrice = displayMoney(item.price); // Display original price
  const total = displayMoney(item.DiscountPrice * item.quantity); // Calculate total for the item based on DiscountPrice

    
  return (
    <Card className={classes.root11}>
      <CardMedia
        className={classes.media}
        image={item.image}
        title={item.name}
      />
      <CardContent className={classes.content}>
        <div className={classes.cartHeader}>
          <Typography variant="subtitle1" className={classes.title}>
            {item.name}
          </Typography>
          <IconButton
            aria-label="delete"
            className={classes.cartDeleteIcon}
            onClick={() => deleteCartItems(item.productId)}
          >
            <DeleteIcon />
          </IconButton>
        </div>

        <div className={classes.priceItem}>
          <Typography className={classes.cartSubHeadings} variant="body2">
            Price:
          </Typography>
          <Typography variant="subtitle1" className={classes.itemPrice}>
            {finalPrice} {/* Shows Discounted Price */}
          </Typography>
          <Typography
            variant="caption"
            component="span"
            className={classes.itemOldPrice}
          >
            {oldPrice} {/* Shows Original Price as Strikethrough */}
          </Typography>
        </div>

        <div className={classes.contentBottom}>
          <div className="prod_details_additem">
            <h5>QTY:</h5>
            <div className="additem">
              <IconButton
                onClick={() => decreaseQuantity(item.productId, item.quantity)}
                className="additem_decrease"
              >
                <RemoveIcon />
              </IconButton>
              <Input
                readOnly
                type="number"
                value={item.quantity}
                className="input"
              />
              <IconButton
                onClick={() =>
                  increaseQuantity(item.productId, item.quantity, item.stock)
                }
                className="additem_increase"
              >
                <AddIcon />
              </IconButton>
            </div>
          </div>

          <div className={classes.priceItem}>
            <Typography variant="body2" className={classes.cartSubHeadings}>
              TOTAL:
            </Typography>
            <Typography variant="subtitle1" className={classes.itemPrice}>
              {total} {/* Shows Total Price (Discounted Price * Quantity) */}
            </Typography>
          </div>

          <Button
            variant="contained"
            className={classes.button}
            onClick={() => deleteCartItems(item.productId)}
          >
            Remove from Cart
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}

export default CartItem;
