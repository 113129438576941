import React, { useState, useEffect, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { load_UserProfile } from "./actions/userAction";
import CricketBallLoader from "./component/layouts/loader/Loader";
import PrivateRoute from "./component/Route/PrivateRoute";
import "./App.css";
import ErrorBoundary from "./component/ErrorBoundiry/ErrorBoundary"; // Import ErrorBoundary

// Import components
import Header from "./component/layouts/Header1.jsx/Header";
import Payment from "./component/Cart/Payment";
import Home from "./component/Home/Home";
import Services from "./Terms&Condtions/Service";
import Footer from "./component/layouts/Footer/Footer";
import ProductDetails from "./component/Product/ProductDetails";
import Products from "./component/Product/Products";
import Signup from "./component/User/SignUp";
import Login from "./component/User/Login";
import Profile from "./component/User/Profile";
import UpdateProfile from "./component/User/UpdateProfile";
import UpdatePassword from "./component/User/UpdatePassword";
import ForgetPassword from "./component/User/ForgetPassword";
import ResetPassword from "./component/User/ResetPassword";
import Shipping from "./component/Cart/Shipping";
import Cart from "./component/Cart/Cart";
import ConfirmOrder from "./component/Cart/ConfirmOrder";
import OrderSuccess from "./component/Cart/OrderSuccess";
import PaymentSuccess from "./component/Cart/PaymentSuccess";
import MyOrder from "./component/order/MyOrder";
import ContactForm from "./Terms&Condtions/Contact";
import AboutUsPage from "./Terms&Condtions/Aboutus";
import ReturnPolicyPage from "./Terms&Condtions/Return";
import TermsUse from "./Terms&Condtions/TermsAndUse";
import TermsAndConditions from "./Terms&Condtions/TermsCondtion";
import PrivacyPolicy from "./Terms&Condtions/Privacy";
import NotFoundPage from './component/Home/404';
import ScrollToTopOnRefresh from './component/Home/ScrollToTop';
import CareerPage from "./Career/Career";
import BlogList from "./component/Blog/blogList";
import BlogDetail from "./component/Blog/BlogDetails";

// Lazy load admin components
const LazyDashboard = React.lazy(() => import("./component/Admin/Dashboard"));
const LazyProductList = React.lazy(() => import("./component/Admin/ProductList"));
const LazyOrderList = React.lazy(() => import("./component/Admin/OrderList"));
const LazyUserList = React.lazy(() => import("./component/Admin/UserList"));
const LazyUpdateProduct = React.lazy(() => import("./component/Admin/UpdateProduct"));
const LazyProcessOrder = React.lazy(() => import("./component/Admin/ProcessOrder"));
const LazyUpdateUser = React.lazy(() => import("./component/Admin/UpdateUser"));
const LazyNewProduct = React.lazy(() => import("./component/Admin/NewProduct"));
const LazyProductReviews = React.lazy(() => import("./component/Admin/ProductReviews"));
const LazyNewBlog = React.lazy(() => import("./component/Admin/CreateBlog"));



function App() {
  const dispatch = useDispatch();

  // Load user profile when the component is mounted
  useEffect(() => {
    dispatch(load_UserProfile());
  }, [dispatch]);

  return (
    <ErrorBoundary> {/* Wrap Routes with ErrorBoundary */}
    <ScrollToTopOnRefresh /> {/* Scroll to top on refresh */}
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<><Header /><Home /><Services /><Footer /></>} />
        <Route path="/product/:id" element={<><Header /><ProductDetails /><Services /><Footer /></>} />
        <Route path="/products" element={<><Header /><Products /><Services /><Footer /></>} />
        <Route path="/products/:keyword" element={<><Header /><Products /><Services /><Footer /></>} />
        <Route path="/signup" element={<><Header /><Signup /><Services /><Footer /></>} />
        <Route path="/login" element={<><Header /><Login /><Services /><Footer /></>} />
        <Route path="/password/forgot" element={<><Header /><ForgetPassword /><Services /><Footer /></>} />
        <Route path="/password/reset/:token" element={<><Header /><ResetPassword /><Services /><Footer /></>} />
        <Route path="/cart" element={<><Header /><Cart /><Services /><Footer /></>} />
        <Route path="/policy/return" element={<><Header /><ReturnPolicyPage /><Services /><Footer /></>} />
        <Route path="/policy/Terms" element={<><Header /><TermsUse /><Services /><Footer /></>} />
        <Route path="/policy/privacy" element={<><Header /><PrivacyPolicy /><Services /><Footer /></>} />
        <Route path="/terms/conditions" element={<><Header /><TermsAndConditions /><Services /><Footer /></>} />
        <Route path="/contact" element={<><Header /><ContactForm /><Footer /></>} />
        <Route path="/about_us" element={<><Header /><AboutUsPage /><Footer /></>} />
        <Route path="/career" element={<><Header /><CareerPage /><Footer /></>} />
        <Route path="/career" element={<><Header /><CareerPage /><Footer /></>} />
        <Route path="/blogs" element={<><Header /><BlogList /><Footer /></>} />
        <Route path="/blog/:slug" element={<><Header /><BlogDetail /><Footer /></>} />

        {/* Protected Routes */}
        <Route
          path="/account"
          element={<PrivateRoute><><Header /><Profile /><Services /><Footer /></></PrivateRoute>}
        />
        <Route
          path="/profile/update"
          element={<PrivateRoute><><Header /><UpdateProfile /><Services /><Footer /></></PrivateRoute>}
        />
        <Route
          path="/password/update"
          element={<PrivateRoute><><Header /><UpdatePassword /><Services /><Footer /></></PrivateRoute>}
        />
        <Route
          path="/orders"
          element={<PrivateRoute><><Header /><MyOrder /><Services /><Footer /></></PrivateRoute>}
        />
        <Route
          path="/shipping"
          element={<PrivateRoute><><Header /><Shipping /><Services /><Footer /></></PrivateRoute>}
        />
        <Route
          path="/order/confirm"
          element={<PrivateRoute><><Header /><ConfirmOrder /><Services /><Footer /></></PrivateRoute>}
        />
        <Route
          path="/success"
          element={<PrivateRoute><><Header /><OrderSuccess /><Services /><Footer /></></PrivateRoute>}
        />

        <Route
          path="/process/payment"
          element={
            <PrivateRoute>
              <>
                <Header />
                <Payment /> {/*  Razorpay is integrated in the Payment component */}
                <Footer />
              </>
            </PrivateRoute>
          }
        />

        <Route
          path="/paymentsuccess"
          element={<PrivateRoute><><Header /><PaymentSuccess /><Footer /></></PrivateRoute>}
        />

        {/* Admin Routes (Lazy-loaded) */}
        <Route
          path="/admin/dashboard"
          element={
            <Suspense fallback={<CricketBallLoader />}>
              <PrivateRoute isAdmin><LazyDashboard /></PrivateRoute>
            </Suspense>
          }
        />
        <Route
          path="/admin/products"
          element={
            <Suspense fallback={<CricketBallLoader />}>
              <PrivateRoute isAdmin><LazyProductList /></PrivateRoute>
            </Suspense>
          }
        />
        <Route
          path="/admin/product/:id"
          element={
            <Suspense fallback={<CricketBallLoader />}>
              <PrivateRoute isAdmin><LazyUpdateProduct /></PrivateRoute>
            </Suspense>
          }
        />
        <Route
          path="/admin/reviews"
          element={
            <Suspense fallback={<CricketBallLoader />}>
              <PrivateRoute isAdmin><LazyProductReviews /></PrivateRoute>
            </Suspense>
          }
        />
        <Route
          path="/admin/orders"
          element={
            <Suspense fallback={<CricketBallLoader />}>
              <PrivateRoute isAdmin><LazyOrderList /></PrivateRoute>
            </Suspense>
          }
        />
        <Route
          path="/admin/order/:id"
          element={
            <Suspense fallback={<CricketBallLoader />}>
              <PrivateRoute isAdmin><LazyProcessOrder /></PrivateRoute>
            </Suspense>
          }
        />
        <Route
          path="/admin/new/product"
          element={
            <Suspense fallback={<CricketBallLoader />}>
              <PrivateRoute isAdmin><LazyNewProduct /></PrivateRoute>
            </Suspense>
          }
        />
         <Route
          path="/admin/new/blog"
          element={
            <Suspense fallback={<CricketBallLoader />}>
              <PrivateRoute isAdmin><LazyNewBlog /></PrivateRoute>
            </Suspense>
          }
        />
        <Route
          path="/admin/users"
          element={
            <Suspense fallback={<CricketBallLoader />}>
              <PrivateRoute isAdmin><LazyUserList /></PrivateRoute>
            </Suspense>
          }
        />
        <Route
          path="/admin/user/:id"
          element={
            <Suspense fallback={<CricketBallLoader />}>
              <PrivateRoute isAdmin><LazyUpdateUser /></PrivateRoute>
            </Suspense>
          }
        />

        <Route path="*" element={<><Header /><NotFoundPage /><Footer /></>} />
      </Routes>
    </ErrorBoundary>
  );
}

export default App;
