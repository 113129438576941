import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import MetaData from "../layouts/MataData/MataData";
import ScrollToTopOnRefresh from "../Home/ScrollToTop";

const BlogHome = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state for UI feedback
  const [error, setError] = useState(null); // Error state to handle errors

  useEffect(() => {
    // Fetch blogs from backend API
    const fetchBlogs = async () => {
      try {
        const response = await axios.get("/api/blogs"); // Replace with your actual API endpoint
        if (response.data.blogs) {
          setBlogs(response.data.blogs.slice(0, 4)); // Set only the 4 most recent blogs
        } else {
          setError("No blogs available.");
        }
      } catch (error) {
        console.error("Error fetching blogs:", error);
        setError("Failed to fetch blogs.");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) {
    return <p className="text-center text-white">Loading blogs...</p>; // Loading message
  }

  if (error) {
    return <p className="text-center text-white">{error}</p>; // Error message
  }

  // Function to extract the first 8 words for phone and 20 words for desktop
  const getFirstWords = (content) => {
    if (content) {
      const strippedContent = content.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
      const words = strippedContent.split(" ");
      const limit = window.innerWidth < 768 ? 7 : 20; // Show 8 words on mobile, 20 on desktop
      return words.slice(0, limit).join(" ") + (words.length > limit ? "..." : "");
    }
    return "";
  };

  return (
    <>
      <ScrollToTopOnRefresh />

      {/* Add MetaData for SEO */}
      <MetaData
        title="Mufa Perfumes Blog | Latest News, Tips, and Fragrance Guides"
        description="Stay updated with the latest news, fragrance tips, and in-depth perfume guides on Mufa Perfumes' blog. Explore insights about perfumes and luxury fragrances."
        keywords="perfume blog, fragrance tips, Mufa Perfumes blog, luxury fragrances, perfume guides, best perfumes"
        ogImage="%PUBLIC_URL%/path-to-blog-image.jpg"
        ogUrl="https://mufaworld.com/blogs"
        canonicalUrl="https://mufaworld.com/blogs"
      />

      <div className="bg-[#001c25] py-4">
        <h2 className="text-3xl font-bold text-center text-white mb-8 blog-title">FRAGRANCE JOURNEYS</h2>
      </div>

      <div className="bg-[#001c25] min-h-screen py-10">
        <div className="container mx-auto px-5">
          <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            {blogs.length > 0 ? (
              blogs.map((blog) => (
                <div
                  key={blog._id}
                  className="bg-white rounded-lg shadow-md overflow-hidden transform transition-transform duration-300 hover:scale-105 hover:shadow-lg"
                >
                  <img
                    src={blog.image}
                    alt={blog.title}
                    className="w-full h-48 object-cover transition-transform duration-300 hover:scale-110"
                  />
                  <div className="p-4">
                    <Link
                      to={`/blog/${blog.slug}`} // Make the title clickable
                      className="text-xl font-bold text-[#001c25] mb-4 hover:underline transition-all duration-300 ease-in-out hover:text-[#d4a24c]"
                    >
                      {/* Mobile: truncate to half title, Desktop: full title */}
                      <span className="block sm:truncate lg:block lg:text-base">
                        {window.innerWidth < 768 ? blog.title.slice(0, 15) + "..." : blog.title}
                      </span>
                    </Link>

                    {/* Show only 8 words on mobile and 20 words on desktop */}
                    <p className="text-gray-700 text-sm mb-4">
                      {getFirstWords(blog.content)}
                    </p>

                    <Link
                      to={`/blog/${blog.slug}`}
                      className="inline-block bg-[#d4a24c] text-white font-semibold px-4 py-2 rounded-md hover:bg-[#b88e3d] transition-colors"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-white">No blogs available.</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogHome;
