import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import MetaData from "../layouts/MataData/MataData";
import ScrollToTopOnRefresh from "../Home/ScrollToTop";

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [totalPages, setTotalPages] = useState(1); // Total number of pages
  const blogsPerPage = 6; // Number of blogs per page

  useEffect(() => {
    const fetchBlogs = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/api/blogs?page=${currentPage}&limit=${blogsPerPage}`);
        if (response.data.blogs) {
          setBlogs(response.data.blogs);
          setTotalPages(response.data.totalPages || 1); // Ensure at least 1 page
        } else {
          setError("No blogs available.");
        }
      } catch (error) {
        console.error("Error fetching blogs:", error);
        setError("Failed to fetch blogs.");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [currentPage]);

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  if (loading) {
    return <p className="text-center text-white">Loading blogs...</p>;
  }

  if (error) {
    return <p className="text-center text-white">{error}</p>;
  }

  return (
    <>
      <ScrollToTopOnRefresh />
      <MetaData
        title="Mufa Perfumes Blog | Latest News, Tips, and Fragrance Guides"
        description="Stay updated with the latest news, fragrance tips, and in-depth perfume guides on Mufa Perfumes' blog. Explore insights about perfumes and luxury fragrances."
        keywords="perfume blog, fragrance tips, Mufa Perfumes blog, luxury fragrances, perfume guides, best perfumes"
        ogImage="%PUBLIC_URL%/path-to-blog-image.jpg"
        ogUrl="https://mufaworld.com/blogs"
        canonicalUrl="https://mufaworld.com/blogs"
      />

      <div className="bg-[#001c25] min-h-screen py-10">
        <div className="container mx-auto px-5">
          <h2 className="text-3xl font-bold text-center text-white mb-8">Blogs</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {blogs.length > 0 ? (
              blogs.map((blog) => (
                <div
                  key={blog._id}
                  className="bg-white rounded-lg shadow-md overflow-hidden"
                >
                  <Link to={`/blog/${blog.slug}`}>
                    <img
                      src={blog.image}
                      alt={blog.title}
                      className="w-full h-48 object-cover"
                    />
                  </Link>
                  <div className="p-4">
                    <Link to={`/blog/${blog.slug}`}>
                      <h3 className="text-xl font-bold text-[#001c25] mb-2">
                        {blog.title}
                      </h3>
                    </Link>
                    <p className="text-gray-700 text-sm mb-4">
                      {blog.description?.slice(0, 100)}...
                    </p>
                    <Link
                      to={`/blog/${blog.slug}`}
                      className="inline-block bg-[#d4a24c] text-white font-semibold px-4 py-2 rounded-lg hover:bg-[#b88e3d] transition-colors"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-white">No blogs available.</p>
            )}
          </div>

          {/* Pagination */}
          <div className="flex justify-center mt-8 items-center space-x-4">
            <button
              onClick={handlePrevious}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-lg ${
                currentPage === 1
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
            >
              Previous
            </button>

            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                className={`px-4 py-2 rounded-lg ${
                  currentPage === index + 1
                    ? "bg-[#d4a24c] text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                }`}
              >
                {index + 1}
              </button>
            ))}

            <button
              onClick={handleNext}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-lg ${
                currentPage === totalPages
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogList;
