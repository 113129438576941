// /frontend/src/store.js

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

// Import Shiprocket's reducer
import { shiprocketReducer } from "./reducers/shiprocketReducer";

// Import other reducers (Product, User, Cart, Order)
import {
  productsReducer,
  productDetailsReducer,
  newReviewReducer,
  newProductReducer,
  deleteUpdateReducer,
  getALLReviewReducer,
  deleteReviewReducer,
} from "./reducers/productReducers";

import {
  profileReducer,
  userReducer,
  forgetPasswordReducer,
  userDetailsReducer,
  allUsersReducer,
} from "./reducers/userReducer";

import { cartReducer } from "./reducers/cartReducer";
import blogReducer from "./reducers/blogReducer"; // Import the blogReducer
import {
  newOrderReducer,
  myOrderReducer,
  orderDetialsReducer,
  allOrdersReducer,
  deletUpdateOrderReducer,
} from "./reducers/orderReducer";

// Combine all reducers into a root reducer
const rootReducer = combineReducers({
  products: productsReducer,
  productDetails: productDetailsReducer,
  userData: userReducer,
  profileData: profileReducer,
  forgetPassword: forgetPasswordReducer,
  cart: cartReducer,
  newOrder: newOrderReducer,
  myOrder: myOrderReducer,
  orderDetails: orderDetialsReducer, // Fixed typo
  addNewReview: newReviewReducer,
  addNewProduct: newProductReducer,
  deleteUpdateProduct: deleteUpdateReducer,
  allOrders: allOrdersReducer,
  deleteUpdateOrder: deletUpdateOrderReducer,
  allUsers: allUsersReducer,
  userDetails: userDetailsReducer,
  deleteReview: deleteReviewReducer,
  getAllReview: getALLReviewReducer,
  shiprocket: shiprocketReducer, // Shiprocket reducer for managing Shiprocket API responses
  blog: blogReducer,
});

// Retrieve cart items and shipping info from localStorage for the initial state
let initialState = {
  cart: {
    cartItems: localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : [],
    shippingInfo: localStorage.getItem("shippingInfo")
      ? JSON.parse(localStorage.getItem("shippingInfo"))
      : {},
    blogInfo: localStorage.getItem("blogInfo")
      ? JSON.parse(localStorage.getItem("blogInfo"))
      : {},
  },
};

// Middleware array to handle async actions (thunk)
const middleware = [thunk];

// Create Redux store with reducers, initial state, and middleware
const store = configureStore({
  reducer: rootReducer, // Pass the combined reducers
  preloadedState: initialState, // Set initial state
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middleware), // Add custom middleware like thunk
  devTools: composeWithDevTools, // Enable Redux DevTools
});

export default store;
